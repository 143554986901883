import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";

export default () => {
  return (
    <Box sx={{ py: 0, borderBottom: 0, borderColor: 'secondary.main' }}>

      <Box sx={{ height: 300, display: "flex", justifyContent: "center", alignItems: "center", backgroundImage: 'url(/background.png)', backgroundSize: 1500 }}>
        <img src="logo.png" style={{ width: 400, width: 400, padding: '0 5 0 5' }} alt="Your Logo" color="secondary.light" />
      </Box>

      {/* <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: 300, backgroundImage: 'url(/background.png)', backgroundSize: 1500 }}>
        <CardMedia image="logo.png" component="img" alt="loading..." sx={{ pt: 10, maxHeight: 200, minHeight: 200 }} />
      </Box> */}

      {/* Web Desktop */}
      {/* <>
        <div className="d-none d-sm-block">
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: 400, backgroundImage: 'url(/background.png)', backgroundSize: 1500 }}>
            <CardMedia image="logo.png" component="img" alt="loading..." sx={{ zIndex: -1 }} />
          </Box>
        </div>
      </> */}

      {/* Web Mobile */}
      {/* <>
        <div className="d-block d-sm-none">
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: 100, backgroundImage: 'url(/background.png)', backgroundSize: 1500 }}>
            <CardMedia image="logo.png" component="img" alt="loading..." sx={{ zIndex: -1 }} />
          </Box>
        </div>
      </> */}

    </Box>
  );
};
