import * as React from "react";
// import { useRef, useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
// import theme from "../../Theme";

export default function VerticalTabs({ data, value, setValue, width }) {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <AppBar position="sticky" sx={{ maxWidth: width, boxShadow: "none", mt: 2 }}>
      <Toolbar sx={{ bgcolor: 'primary.main', py: 1, backgroundImage: 'url(/background.png)', backgroundSize: 1500 }}>
        <Tabs value={value} onChange={handleChange} variant="scrollable" sx={{
          bgcolor: 'primary.main', maxWidth: "100%", backgroundImage: 'url(/background.png)', backgroundSize: 1500,
          // "&:hover": {
          //   bgcolor: '#202020',
          //   color: '#2f2f2f',
          // },
        }}>
          {data?.length > 0 && data?.map((t, i) => (
            <Tab key={i} label={t?.name}
              sx={{ mx: 0.5, borderRadius: 50, bgcolor: 'secondary.dark', "&.Mui-selected": { color: 'secondary.light' } }}
            />
          ))}
        </Tabs>
      </Toolbar>
    </AppBar>
  );

  // return (
  //   <AppBar position="sticky" style={{ boxShadow: "none", marginTop: 10, marginBottom: 0 }}>
  //     <Toolbar sx={{ bgcolor: 'primary.main' }}>
  //       <Tabs
  //         sx={{ bgcolor: 'primary.main' }}
  //         value={value}
  //         onChange={handleChange}
  //         variant="scrollable"
  //         style={{ maxWidth: "100%" }}
  //       >
  //         {data?.length > 0 && data?.map((t, i) => (
  //           <Tab
  //             key={i}
  //             label={t?.name}
  //             sx={{
  //               mx: 0.5,
  //               borderRadius: 5,
  //               bgcolor: "primary.main",
  //               minHeight: 'auto',
  //               "&.Mui-selected": {
  //                 color: "secondary.light",
  //                 bgcolor: "secondary.main",
  //               },
  //             }}
  //           />
  //         ))}
  //       </Tabs>
  //     </Toolbar>
  //   </AppBar>

  // );
}
