import createTheme from "@mui/material/styles/createTheme";
import * as Colors from '@mui/material/colors';
// import exo from './exo.woff2'
// import ExoRegular from './Exo-Regular.woff'
// import exo from 'https://fonts.googleapis.com/css2?family=Exo:wght@100&display=swap'

export const light = createTheme({
  typography: {
    // fontFamily: 'Lato, Arial'
  },
  palette: {
    light: {
      light: "#fffff",
      // light: '#757ce8',
      main: "#fff",
      // dark: '#002884',
      // contrastText: '#000',
    },
    primary: {
      light: "#fffff",
      // light: '#757ce8',
      main: "#fffff",
      dark: '#002884',
      // contrastText: '#000',
    },
    secondary: {
      light: '#DE542C',
      main: "#f44336",
      //   dark: '#ba000d',
      //   contrastText: '#000',
    },
    // background: {
    // paper: '#e4e4e4',
    // default: '#e4e4e4',
    // }
  },
  // components: {
  //   MuiTab: {
  //     root: {
  //       "&.Mui-selected": {
  //         color: '#F15C22',
  //       },
  //     },
  //     selected: {
  //       color: '#F15C22',
  //     }
  //   },
  // },
});

export const dark = createTheme({
  typography: {
    fontFamily: 'Exo, Montserrat-SemiBold, arial',
    fontSize: 16,
    fontWeightBold: 400,
  },
  components: {
    // MuiCssBaseline: {
    //   styleOverrides: `
    //     @font-face {
    //       font-family: 'Exo';
    //       font-style: normal;
    //       font-display: swap;
    //       font-weight: 400;
    //       src: local('Exo'), local('Exo'), url(${exo}) format('woff2');
    //       unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    //     }
    //   `,
    // },
    // MuiTabs: {
    //   styleOverrides: {
    //     root: {
    //       "&.Mui-selected": {
    //         color: 'secondary.light',
    //       },
    //       color: 'red',
    //       bgcolor: '#fff',
    //     },
    //   },
    // },
    // "&.Mui-selected": {
    //   styleOverrides: {
    //     root: ({ theme }) => theme.unstable_sx({
    //       bgcolor: 'red',
    //       color: 'red',
    //     }),
    //   }
    // },
    MuiIconButton: {
      styleOverrides: {
        root: ({ theme }) => theme.unstable_sx({
          color: Colors.grey[300],
          // bgcolor: Colors.grey[400],
          '&:hover': {
            // bgcolor: Colors.grey[400],
          }
        }),
      },
    },
    // MuiChip: {
    //   styleOverrides: {
    //     root: ({ theme }) => theme.unstable_sx({
    //       // color: Colors.red[300],
    //       width: "100%",
    //       borderRadius: 0,
    //       // my: 1,
    //       fontSize: 18,
    //       // fontFamily: "Lato",
    //       backgroundColor: "secondary.dark",
    //     }),
    //   },
    // },
    MuiTabs: {
      styleOverrides: {
        root: ({ theme }) => theme.unstable_sx({
          // hover: {
          //   backgroundColor: '#202020',
          //   color: '#2f2f2f'
          // }
          // backgroundColor: '#202020',
          // bgcolor: 'red',
          // px: 1,
          // py: 0.25,
          // borderRadius: 1,
          // color: "red",
          // textDecorationColor: 'red',
          // "&.Mui-selected": {
          //   color: 'red'
          // },
          // "&:hover": {
          //   bgcolor: Colors.grey[400],
          //   color: Colors.grey[400],
          // },
        }),
        label: {
          // color: 'red',
          // padding: 'initial',
        },
        icon: ({ theme }) => theme.unstable_sx({
          // mr: 0.5,
          // ml: '-2px',
        }),
      },
      // root: {
      //   // color: this?.dark?.palette?.secondary?.light,
      //   // color: '#ffffff',
      //   backgroundColor: '#202020',
      //   // color: '#fff',
      // },
    },
  },

  light: {
    light: "#fff",
    // light: '#757ce8',
    main: "#fff",
    // dark: '#002884',
    // contrastText: '#000',
  },
  palette: {
    mode: "dark",
    primary: {
      light: "#fff",
      main: "#383A3C",
      dark: "#484b4c",
      //   contrastText: '#000',
    },
    secondary: {
      light: '#fff',
      main: "#DE542C",
      dark: '#c2421e',
      // dark: '#383A3F',
      // contrastText: '#000',
    },
    background: {
      paper: '#383A3C',
      // default: '#383A3C',
    },
    light: {
      // light: "#fff",
      // light: '#757ce8',
      main: "#ff0",
      // dark: '#002884',
      // contrastText: '#000',
    },
  },
  // components: {
  //   MuiTab: {
  //     root: {
  //       "&.Mui-selected": {
  //         color: '#F15C22',
  //       },
  //     },
  //     selected: {
  //       color: '#F15C22',
  //     }
  //   },
  // },
});

// #DE542C Orange
// #383A3C Noir

export default light;
