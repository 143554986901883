import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Grow from "@mui/material/Grow";
import Product from "./Product";
import Cart from "./Cart";
import theme from '../Theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const useContainerDimensions = (myRef) => {
  const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 });

  React.useEffect(() => {
    const getDimensions = () => ({
      width: myRef.current.offsetWidth,
      // height: myRef.current.offsetHeight,
    });

    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myRef]);

  return dimensions;
};

export default function Contains({ data, value, order, set_order }) {
  // const ref = React.useRef(null);
  const [cart_width, set_cart_width] = React.useState(0);
  const [cart_height, set_cart_height] = React.useState(0);
  const is_sm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      {data?.length > 0 && data?.map((c, i) => (
        <TabPanel id={'TabPanel-' + i} key={i} value={value} index={i}>
          <Box sx={{ mx: is_sm ? -2 : 0 }}>
            <Box sx={{ pb: 2, pl: 2 }}>
              <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }} columnSpacing={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                <Typography item color='secondary.main' sx={{ fontSize: is_sm ? 35 : 60, fontWeight: 900 }}>{c?.name}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'end', pb: is_sm ? 0.6 : 2.2, pl: 1 }}>
                  <Typography item color='secondary.light' component="p" sx={{ fontSize: 20 }}>{c?.description}</Typography>
                </Box>
              </Grid>
            </Box>

            <Grid container rowSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
              {c?.products?.length > 0 && Object.values(c?.products)?.map((p, i) =>
                <Grid item xs={6} sm={6} md={4} lg={3} >
                  <Grow key={p?.id} in={true} {...{ timeout: 500 * i }}>
                    <Box>
                      <Product category={c} product={p} order={order} set_order={set_order} />
                    </Box>
                  </Grow>
                </Grid>
              )}
            </Grid>
          </Box>
        </TabPanel>
      ))}

      <Cart order={order} set_order={set_order} set_cart_width={set_cart_width} set_cart_height={set_cart_height} />

      <Box sx={{ bottom: 0, textAlign: "center", opacity: 0.9, color: 'secondary.light', py: 2, backgroundImage: 'url(/background.png)', backgroundSize: 1500 }}>
        <Box>Copyright &copy; 2023 Nori Restaurant</Box>
        <Box>
          Designed by <Link sx={{ color: 'secondary.main' }} target='_blank' href='https://founder-technology.com/'>Founder Technology</Link>
        </Box>
      </Box>
    </Box>
  );
}
