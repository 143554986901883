import * as React from 'react';
import PropTypes from 'prop-types';
import { useState } from "react";
import Box from "@mui/material/Box";
import { MdChecklist, MdCheck, MdShoppingCart, MdClose, MdAddShoppingCart } from "react-icons/md";
import { styled } from "@mui/material/styles";
import { Button, Grid, Typography, IconButton, CircularProgress, Dialog, CardMedia, } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from "../../Theme";
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CheckoutModal = ({ open, onClose, image, product, handleClick }) => {

  const is_sm = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, set_loading] = useState(true);

  return (
    <Dialog fullScreen={is_sm} open={open} onClose={onClose} TransitionComponent={Transition}
      PaperProps={{
        style: { borderRadius: 20 }
      }}
    >
      <Box sx={{ height: '100%', backgroundImage: 'url(/background.png)', backgroundSize: 300 }}>

        <IconButton
          edge="end" color="inherit" onClick={onClose} aria-label="close"
          sx={{ position: 'absolute', mx: 2, my: 2, left: 0, bgcolor: '#ffffff20' }}
        >
          <MdClose />
        </IconButton>

        <Box>
          <Grid justifyContent="center" alignItems="center">
            <Box sx={{ textAlign: 'center' }}>
              {loading && <CircularProgress color="secondary" sx={{ mt: '33vh', position: 'absolute' }} />}
              <CardMedia component="img" alt="loading..." image={image}
                sx={{ maxHeight: '66vh', bgcolor: "primary.main", p: 0, m: 0, width: '100%', visibility: loading ? 'hidden' : '' }}
                onLoadCapture={() => set_loading(false)}
                onErrorCapture={() => set_loading(false)}
              />
            </Box>
          </Grid>
          <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} sx={{ py: 2, px: 2 }}>
            <Grid item xs={12} sx={{ textAlign: 'start' }}>
              <Typography fontSize={25}>{product?.name}</Typography>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'start', mb: 2 }}>
              <Typography fontSize={15}>{product?.designation}</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'center' }}>
              <Typography>{product?.price} {JSON.parse(localStorage.getItem("configs"))?.currency ?? '$'}</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'center' }}>
              <Button onClick={() => { handleClick(product); onClose() }} color="secondary" startIcon={<MdAddShoppingCart color="" />}>
                <Typography color="secondary.dark">Add To Cart</Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>

      </Box>

    </Dialog >
  )
};

export default CheckoutModal;
