import * as React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import InputAdornment from "@mui/material/InputAdornment";
import { MdMapsHomeWork, MdChecklist, MdPerson, MdCheck, MdPhone, MdNoteAdd, MdShoppingCart, MdLabelImportant, MdDone, MdClose, MdRemove, MdAdd } from "react-icons/md";
import ProductList from "../ProductsListCart";
import { styled } from "@mui/material/styles";
import { Button, Grid, TextField, Typography, Paper, StepLabel, Stepper, Step, IconButton, CircularProgress, Backdrop, Dialog, Autocomplete, Table, TableFooter, TableRow, TableCell, TableContainer, TableHead, TableBody, Avatar, DialogContent, DialogActions, } from "@mui/material";
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from "../../Theme";
import Slide from '@mui/material/Slide';

import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Snackbar from '@mui/material/Snackbar';
import { n_format } from '../../Functions/n_format';

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <MdShoppingCart className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <MdShoppingCart size={25} />,
    2: <MdChecklist size={25} />,
    3: <MdCheck size={25} />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// function FullScreenDialog() {
//   const [open, setOpen] = React.useState(false);

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   return (
//     <div>
//       <Button variant="outlined" onClick={handleClickOpen}>
//         Open full-screen dialog
//       </Button>
//       <Dialog
//         fullScreen
//         open={open}
//         onClose={handleClose}
//         TransitionComponent={Transition}
//       >
//         <AppBar sx={{ position: 'relative' }}>
//           <Toolbar>
//             <IconButton
//               edge="start"
//               color="inherit"
//               onClick={handleClose}
//               aria-label="close"
//             >
//               <MdClose />
//             </IconButton>
//             <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
//               Sound
//             </Typography>
//             <Button autoFocus color="inherit" onClick={handleClose}>
//               save
//             </Button>
//           </Toolbar>
//         </AppBar>
//         <List>
//           <ListItem button>
//             <ListItemText primary="Phone ringtone" secondary="Titania" />
//           </ListItem>
//           <Divider />
//           <ListItem button>
//             <ListItemText
//               primary="Default notification ringtone"
//               secondary="Tethys"
//             />
//           </ListItem>
//         </List>
//       </Dialog>
//     </div>
//   );
// }

const CheckoutModal = ({ order, set_order, open, onClose, products }) => {
  const steps = ['Votre Panier', 'Formulaire', 'Confirmation'];
  const [nomcomplete, setNomcomplete] = useState("");
  const [Adress, setAdress] = useState("");
  const [neighborhood, set_neighborhood] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [Note, setNote] = useState("");
  const [step, set_step] = useState(0);
  const [open_back_drop, set_open_back_drop] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [is_livraison, set_is_livraison] = useState(false);
  const [open_snackbar, set_open_snackbar] = React.useState(false);
  const [snackbar_message, set_snackbar_message] = React.useState('');
  const domRef = React.useRef();
  const is_sm = useMediaQuery(theme.breakpoints.down('sm'));

  const enqueueSnackbar = (m) => {
    set_snackbar_message(m)
    set_open_snackbar(true)
    return new Promise((resolve) => {
      setTimeout(() => {
        set_open_snackbar(false)
        resolve();
      }, 5000);
    });
  };

  // useEffect(() => {
  //   console.log(domRef.clientHeight);
  // }, [domRef]);

  useEffect(() => {
    if (open_snackbar) set_open_snackbar(false)
  }, [nomcomplete, PhoneNumber, Adress, neighborhood]);

  const ConfirmOrder = async () => {
    set_open_snackbar(false)
    if (nomcomplete == '') enqueueSnackbar('Merci de saisir votre nom')
    else if (PhoneNumber == '') enqueueSnackbar('Merci de saisir votre phone')
    else if (is_livraison && Adress == '') enqueueSnackbar('Merci de saisir votre adresse')
    else if (is_livraison && neighborhood == '') enqueueSnackbar('Merci de saisir votre quartier')
    else {
      set_open_back_drop(true)
      await axios
        .post(`${process.env.REACT_APP_API_URL}orders_store`, {
          products: Object.values(products)?.map(p => ({ product_id: p?.id, qte: p?.qte })),
          status_id: 10, type: is_livraison ? 10 : 20, name_client: nomcomplete, phone: PhoneNumber, adresse: Adress, notes: Note, neighborhood_id: neighborhood
        })
        .then((res) => {
          if (!res?.data?.result) enqueueSnackbar(res?.data?.message)
          if (res?.data?.result == true) {
            set_order({ products: [], total: 0 })
            set_step(2)
          }
        })
        .catch((err) => enqueueSnackbar('Erreur Côte Serveur: La commande non effectuée'))
      set_open_back_drop(false)
    }
  }

  const set_qte = async (id, qte) => {
    await set_order({ ...order, products: order?.products.map((p) => p?.id == id ? { ...p, qte: qte } : p) });
  };


  return (
    <Dialog rootRef={domRef} fullScreen={fullScreen} maxWidth='md' open={open} TransitionComponent={Transition} onClose={() => { set_step(0); onClose() }}
      PaperProps={{
        style: { borderRadius: 20 }
      }}
    >

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open_snackbar}
        // onClose={() => set_open_snackbar(false)}
        TransitionComponent={(props) => <Slide {...props} direction="left" />}
        message={snackbar_message}
      />

      <AppBar sx={{ position: 'relative', mb: 3 }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => { set_step(0); onClose() }} aria-label="close">
            <MdClose />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">{steps[step] ?? ''}</Typography>
          {/* {step < 2 && ((order?.total ?? 0) >= 200) && <Button autoFocus color="inherit" onClick={() => { set_step(step + 1) }}>Next</Button>} */}
        </Toolbar>
      </AppBar>

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open_back_drop}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Stack sx={{ width: '100%', pb: 4 }} spacing={4}>
        <Stepper alternativeLabel activeStep={step} connector={<ColorlibConnector />}>
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>

      {step === 0 &&

        <Grid container sx={{ height: '100%' }}>

          <Grid item xs={12} sx={{
            maxHeight: is_sm ? '51vh' : '35vh',
            overflow: 'auto',
            scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': {
              width: '0.4em',
            },
            '&::-webkit-scrollbar-track': {
              background: "#f1f1f1",
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#555'
            }
          }}>

            <Table size="small" sx={{ backgroundColor: "primary.main" }}>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Quantity</TableCell>
                  <TableCell align="center">Total</TableCell>
                </TableRow>
              </TableHead>
            </Table>

            {/* <TableContainer sx={{ backgroundColor: "primary.main", height: '20vh' }}> */}
            <Table size="small" sx={{ backgroundColor: "primary.main" }}>
              <TableBody>
                {products && Object.values(products).map((row) =>
                  <TableRow key={row?.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell sx={{ display: 'flex', alignItems: 'center', whiteSpace: "nowrap1" }}>
                      <Avatar src={process.env.REACT_APP_URL + "storage/" + row?.image} />
                      <Box sx={{ ml: 1 }}>
                        <Typography fontSize={15} color='secondary'>{row?.category?.name}</Typography>
                        <Typography fontSize={15}>{row?.name}</Typography>
                        <Typography fontSize={10}>{n_format(row?.price, 0)} {JSON.parse(localStorage.getItem("configs"))?.currency ?? '$'}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <Box sx={{ display: 'inline-flex' }}>
                        <IconButton onClick={() => set_qte(row?.id, row?.qte - 1)}><MdRemove /></IconButton>
                        <IconButton sx={{ textAlign: 'center', px: 1, fontSize: 16 }} >{row?.qte}</IconButton>
                        <IconButton sx={{ textAlign: 'center' }} onClick={() => set_qte(row?.id, row?.qte + 1)}><MdAdd /></IconButton>
                      </Box>
                    </TableCell>
                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>{n_format(row?.price * row?.qte, 0)} {JSON.parse(localStorage.getItem("configs"))?.currency ?? '$'}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {/* </TableContainer > */}

          </Grid>

          <Grid item xs={12}>
            <Table size="small" sx={{ color: "secondary.light" }}>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={3} align='right' sx={{ fontSize: 18, py: 1 }}>
                    <Box sx={{ whiteSpace: "nowrap", opacity: 0.8 }}>Sous-Total : {n_format(order?.total, 2)} {JSON.parse(localStorage.getItem("configs"))?.currency ?? '$'}</Box>
                    <Box sx={{ whiteSpace: "nowrap", opacity: 0.8 }}>5 % de service : {n_format(order?.total * 0.05, 2)} {JSON.parse(localStorage.getItem("configs"))?.currency ?? '$'}</Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={3} align='right' sx={{ fontSize: 22, py: 1 }}>
                    <Box sx={{ fontSize: 22, whiteSpace: "nowrap" }}>Total : {n_format(order?.total * 1.05, 2)} {JSON.parse(localStorage.getItem("configs"))?.currency ?? '$'}</Box>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>

            {
              is_livraison && !((order?.total ?? 0) * 1.05 >= 200) &&
              <Grid item xs={12} sx={{ textAlign: 'center', py: 2, px: 1 }}>
                <Typography color='secondary.main'>Ajoutez des éléments jusqu'à atteindre un minimum de 200.00 Dhs (pour la livraison)</Typography>
              </Grid>
            }

            <Grid container xs={12} sm={12} md={12} lg={12} sx={{ px: 2, py: 3, width: '100%' }} >
              <Typography sx={{ fontSize: 25, borderRadius: 10, p: 1.5, mx: '2.5%', width: '45%', textAlign: 'center', bgcolor: 'secondary.main' }}
                onClick={() => {
                  set_is_livraison(true)
                  if ((order?.total ?? 0) * 1.05 >= 200) set_step(1)
                }}
              >
                Livraison
              </Typography>
              <Typography sx={{ fontSize: 25, borderRadius: 10, p: 1.5, mx: '2.5%', width: '45%', textAlign: 'center', bgcolor: 'secondary.main' }}
                onClick={() => {
                  set_is_livraison(false)
                  set_step(1)
                }}
              >
                Emporter
              </Typography>
            </Grid>
          </Grid>

        </Grid >
      }

      {
        step === 1 &&
        <Box sx={{ px: 5, py: 2, pt: 2 }}>
          <Typography align='center' variant="h6" gutterBottom sx={{ pb: 2 }}>Remplir le formulaire</Typography>

          <Box style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <TextField required label="Nom Complet" color="secondary" variant="standard"
              value={nomcomplete}
              onChange={(e) => setNomcomplete(e.target.value)}
            // InputProps={{ startAdornment: (<InputAdornment position="start"><MdPerson /></InputAdornment>), }}
            />

            <TextField required label="Numéro Téléphone" color="secondary" autoComplete="false" variant="standard"
              value={PhoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            // InputProps={{ startAdornment: (<InputAdornment position="start"><MdPhone /></InputAdornment>), }}
            />

            {is_livraison &&
              <TextField required label="Adresse Complète" color="secondary" variant="standard"
                value={Adress}
                onChange={(e) => setAdress(e.target.value)}
              // InputProps={{ startAdornment: (<InputAdornment position="start"><MdMapsHomeWork /></InputAdornment>), }}
              />
            }
            {is_livraison &&
              <Autocomplete
                size="small"
                options={[
                  { key: 1, value: 'Ain Diab' },
                  { key: 2, value: 'Anfa Finance City' },
                  { key: 3, value: 'Anfa Superieur' },
                  { key: 4, value: 'Bourgogne' },
                  { key: 5, value: 'Californie' },
                  { key: 6, value: 'Centre Ville' },
                  { key: 7, value: 'CIL' },
                  { key: 8, value: 'Deux Mars' },
                  { key: 9, value: 'Ferme Bretonne' },
                  { key: 10, value: 'Gauthier' },
                  { key: 11, value: 'Maarif' },
                  { key: 12, value: 'Maarif Extension' },
                  { key: 13, value: 'Mers Sultan' },
                  { key: 14, value: 'Oasis' },
                  { key: 15, value: 'neighborhood Californie' },
                  { key: 16, value: 'Route d\'azzemoure (avant ecole goeorge washington)' },
                  { key: 17, value: 'Sidi Maarouf' },
                  { key: 18, value: 'Val Fleuri' },
                ]}
                // onChange={(e) => setneighborhood(e.target.value)}
                getOptionLabel={(o) => o?.value}
                onChange={(event, newValue) => {
                  set_neighborhood(newValue?.key)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Quartier *"
                    color="secondary"
                  // SelectProps={{ startAdornment: (<InputAdornment position="start"><MdMapsHomeWork /></InputAdornment>), }}
                  // InputProps={{ startAdornment: (<InputAdornment position="start"><MdMapsHomeWork /></InputAdornment>), }}
                  />
                )}
              />
            }

            <TextField label="Notes" color="secondary" autoComplete="false" variant="standard"
              value={Note}
              onChange={(e) => setNote(e.target.value)}
            // InputProps={{ startAdornment: (<InputAdornment position="start"><MdNoteAdd /></InputAdornment>), }}
            />

            <Box sx={{ display: "flex", justifyContent: "center", gap: 15, mt: 2, mb: 2 }}>
              <Button variant="contained" onClick={() => set_step(0)}>Cancel</Button>
              <Button variant="contained" endIcon={<MdLabelImportant />} onClick={ConfirmOrder}>Confirm</Button>
            </Box>
          </Box>

        </Box>
      }

      {
        step === 2 &&
        <Paper sx={{ py: 4, px: 2, textAlign: 'center' }}>
          <IconButton sx={{ mb: 3, border: 4, borderColor: 'green' }}>
            <MdDone color="green" size={60} />
          </IconButton>
          <Typography>Votre commande a été effectuée avec succès</Typography>
        </Paper>
      }

      {/* <List>
        <ListItem button>
          <ListItemText primary="Phone ringtone" secondary="Titania" />
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemText
            primary="Default notification ringtone"
            secondary="Tethys"
          />
        </ListItem>
      </List> */}
    </Dialog >
  )

  // return (
  //   <Dialog fullScreen={fullScreen} open={open} TransitionComponent={Transition}
  //     onClose={() => { set_step(0); onClose() }}
  //     sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
  //     {/* <Paper sx={{ p: 0, display: "flex", maxWidth: "90%", maxHeight: "90%", overflow: "auto", p: 1, paddingTop: 2, borderRadius: 7, alignItems: "center", flexDirection: "column", backgroundColor: "primary.main", }}> */}

  //     <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open_back_drop}>
  //       <CircularProgress color="inherit" />
  //     </Backdrop>

  //     <Stack sx={{ width: '100%', pb: 5 }} spacing={4}>
  //       <Stepper alternativeLabel activeStep={step} connector={<ColorlibConnector />}>
  //         {steps.map((label, index) => (
  //           <Step key={index}>
  //             <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
  //           </Step>
  //         ))}
  //       </Stepper>
  //     </Stack>

  //     {step === 0 &&
  //       <Grid container>
  //         <Grid item xs={12}>
  //           <Box sx={{ flexGrow: 1 }}>
  //             <Grid>
  //               <Typography variant="h5" sx={{ textAlign: 'center', pb: 3 }}>Shopping Cart</Typography>
  //               <ProductList order={order} set_order={set_order} products={products} />
  //             </Grid>
  //           </Box>
  //         </Grid>
  //         <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", mt: 3, pr: 2, pb: 2 }}>
  //           <Button variant="contained" endIcon={<MdLabelImportant />} onClick={() => set_step(1)}>Next</Button>
  //         </Grid>
  //       </Grid>
  //     }

  //     {
  //       step === 1 &&
  //       <Box sx={{ flexGrow: 1, width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", gap: "16px", }}>
  //         <Typography variant="h6" gutterBottom>Fill out the form</Typography>

  //         <Box style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
  //           <TextField id="input-with-icon-textfield" label="Nom Complete" color="secondary" variant="standard"
  //             value={nomcomplete}
  //             onChange={(e) => setNomcomplete(e.target.value)} InputProps={{ startAdornment: (<InputAdornment position="start"><MdPerson /></InputAdornment>), }}
  //           />

  //           <TextField id="input-with-icon-textfield" label="Adress Complete" color="secondary" variant="standard"
  //             value={Adress}
  //             onChange={(e) => setAdress(e.target.value)}
  //             InputProps={{ startAdornment: (<InputAdornment position="start"><MdMapsHomeWork /></InputAdornment>), }}
  //           />

  //           <TextField id="input-with-icon-textfield" label="Numero Telephone" color="secondary" autoComplete="false" variant="standard"
  //             value={PhoneNumber}
  //             onChange={(e) => setPhoneNumber(e.target.value)}
  //             InputProps={{ startAdornment: (<InputAdornment position="start"><MdPhone /></InputAdornment>), }}
  //           />

  //           <TextField id="input-with-icon-textfield" label="Notes : " color="secondary" autoComplete="false" variant="standard"
  //             value={Note}
  //             onChange={(e) => setNote(e.target.value)}
  //             InputProps={{ startAdornment: (<InputAdornment position="start"><MdNoteAdd /></InputAdornment>), }}
  //           />

  //           <Box sx={{ display: "flex", justifyContent: "center", gap: "16px" }}>
  //             <Button variant="contained" onClick={() => set_step(0)}>Cancel</Button>
  //             <Button variant="contained" endIcon={<MdLabelImportant />} onClick={ConfirmOrder}>Confirm</Button>
  //           </Box>
  //         </Box>
  //       </Box>
  //     }

  //     {
  //       step === 2 &&
  //       <Paper sx={{ py: 4, textAlign: 'center' }}>
  //         <IconButton sx={{ mb: 3, border: 4, borderColor: 'green' }}>
  //           <MdDone color="green" size={60} />
  //         </IconButton>
  //         <Typography>Votre commande a été effectuée avec succès</Typography>
  //       </Paper>
  //     }

  //     {/* </Paper > */}
  //   </Dialog >
  // );
};

export default CheckoutModal;
